<template>
  <div class="main-card mb-3 card pa-0 ma-0">
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-body pa-0">
      <div class="card-title card-header-tab card-header">

        <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ (this.items == null) && this.$t('erp.lang_createOrder') || this.$t('erp.lang_prepareOrder') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn" v-if="this.items != null">
          <v-btn class="pa-0" text>
            <label class="ma-0 pa-3" style="cursor:pointer;">
              <input v-model="prepareForm.showAll" class="dropdown-icon" type="checkbox" value="true"/>
              {{$t('generic.lang_Showall')}}
            </label>
          </v-btn>
          
          <v-btn @click="clear" class="" text>
            <v-icon class="dropdown-icon" color="error">refresh</v-icon>
            <span>{{ $t('generic.lang_clear') }}</span>
          </v-btn>
        </div>
      </div>
      <v-divider class="pa-0 ma-0"/>

      <v-form lazy-validation ref="prepare_form">
        <v-row align="center" class="mt-3 pa-5" justify="center" v-if="this.items == null">
          <v-col cols="12" md="6" sm="6">
            <v-text-field :label="this.$t('erp.lang_orderReference')" dense outlined
                          v-model="prepareForm.orderName" @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"/>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-select :items="this.suppliers" :label="this.$t('erp.lang_pleaseSelectSupplier')"
                      :rules="[ v => !!v]" dense
                      clearable
                      item-text="supplier_name"
                      item-value="id" outlined v-model="prepareForm.supplierID"/>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-select :items="this.storages" :label="this.$t('erp.lang_chooseWarehouse')"
                      :rules="[ v => !!v]" dense clearable
                      item-text="storageName"
                      item-value="storageID" outlined v-model="prepareForm.storageID"/>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- <birthdaypicker :current="true" :min="new Date().toISOString()" :rules="[ v => !!v]" dense
                             label="Expected on"
                             outlined prepend-icon="" type="date"
                             v-bind:current-date="prepareForm.expectedOn"
                             v-model="prepareForm.expectedOn"/> -->
            <BaseDateInput :rules="[ v => !!v]" dense outlined type="date" :label="$t('erp.lang_goodsExpectedOn')" v-model="prepareForm.expectedOn" clearable/>
          </v-col>

          <v-col cols="12" sm="12">
            <b-form-group :label="this.$t('erp.lang_orderDeliveryInstructions')">
              <b-form-textarea :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" v-model="prepareForm.note" @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"></b-form-textarea>
            </b-form-group>
          </v-col>
          <v-col cols="12" sm="12">
            <v-btn :disabled="loading" :loading="loading" @click="loadItems"
                   class="btn btn-primary white--text mx-auto btn-block  btn-lg" color="bg-primary">
              {{ this.$t('erp.lang_prepareOrder') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="pa-5" align="center" justify="center" v-if="this.items != null">
        <v-col cols="12" sm="6" >
          <v-text-field
              outlined
              dense
              hide-details
              :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
              :label="$t('erp.lang_ware_create_id')"
              :data-layout="KEYBOARD.KEYSETS.NORMAL"
              @focus="showTouchKeyboard"
              v-model="prepareForm.itemID"
          />
        </v-col>
        <v-col cols="12" sm="6" >
          <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="itemGroups"
                    :label="$t('generic.lang_waregroup')"
                    hide-details
                    @focus="showTouchKeyboard" dense item-text="name"
                    item-value="id" outlined clearable
                    v-model="prepareForm.wareGroupID"/>
        </v-col>
        <v-col cols="12" sm="6" >
          <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="producers"
                    :label="this.$t('erp.lang_nav_manufacturer')"
                    hide-details
                    clearable
                    @focus="showTouchKeyboard" dense item-text="producer_name"
                    item-value="id" outlined
                    v-model="prepareForm.producerID"/>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-if="this.items != null">
        <v-col cols="12">
          <v-col cols="12" sm="12">
            <b-form-group :label="this.$t('erp.lang_orderDeliveryInstructions')">
              <b-form-textarea v-model="prepareForm.note"></b-form-textarea>
            </b-form-group>
          </v-col>
          <v-data-table :headers="headers" :items="items" :search="search" hover striped :loading="loading">
            <template v-slot:item.name="{item}">
              <strong class="text-primary">{{ item.name }} ({{ item.buy_price }})</strong>
            </template>
            <template v-slot:item.StoreValue="{item}">
              <v-chip :color="getColor(item.StoreValue , item.minBestand , item.sollBestand)"
                      class="mr-2"
                      small>
                {{ item.StoreValue }}
              </v-chip>
            </template>

            <template v-slot:item.orderQTY="{item}">
              <b-form-input class="mx-auto" style="max-width: 60px"
                            v-model="item.orderQTY"></b-form-input>
            </template>

            <template v-slot:item.vpe="{item}">
              <strong>
                {{
                  (Number(item.orderQTY / item.unitPerPackage) &&
                      (item.orderQTY / item.unitPerPackage % 1
                          === 0)) ? item.orderQTY / item.unitPerPackage :
                      ((item.orderQTY / item.unitPerPackage == 0) ? 0
                          : parseInt(item.orderQTY / item.unitPerPackage) + 1)
                }}
              </strong>
            </template>

            <template v-slot:item.actions="{item}">
              <v-btn @click="deleteItem(item)" color="error" icon>
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>

        <v-col class="pl-4 pr-4" cols="12">
          <v-btn :disabled="this.items === null || (this.items && this.items.length == 0) || this.crateLoading"
                 :loading="this.crateLoading"
                 @click="create"
                 block
                 class="btn text-light bg-primary mx-auto">
            {{ this.$t('erp.lang_createAndCloseOrder') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns";
import Template from "../../../settings/customerdisplay/Template";
import Birthdaypicker from "../../../common/birthdaypicker";
import Users from "../../../common/Users";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "CreateOrderComponent",
  components: {Users, Birthdaypicker, Template, BaseDateInput},
  props: {
    tab: {
      type: String,
      default: "tab1"
    }
  },
  mixins: [mixin],
  data: () => {
    return {
      search: '',
      suppliers: [],
      producers: [],
      storages: [],
      itemGroups: [],
      loading: false,
      prepareForm: {
        storageID: 0,
        supplierID: 0,
        showAll:false,
        itemID:null,
        wareGroupID:null,
        producerID:null,
        orderName:null,
        expectedOn:null
      },
      items: null,
      itemsHistory:{},
      awaitingSearch: null,
      crateLoading: false,
    }
  },
  async mounted() {
    this.loadData();
    this.getProducers();
    await this.getItemGroups();
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_identcode'),
          value: 'identcode'
        },
        {
          text: this.$t('erp.lang_orderNo'),
          value: 'supplier_num'
        },
        {
          text: this.$t('erp.lang_itemname'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('erp.lang_actualStock'),
          value: 'StoreValue',
          align: "center"
        },
        {
          text: this.$t('erp.lang_warecreate_minqty'),
          value: 'minBestand'
        },
        {
          text: this.$t('erp.lang_warecreate_sollQTY'),
          value: 'sollBestand'
        },
        {
          text: this.$t('erp.lang_orderQTY'),
          value: 'orderQTY',
          align: "center"
        },
        {
          text: this.$t('erp.lang_orderQtyVpe'),
          value: 'vpe',
          align: "center"
        },
        {
          text: "",
          value: 'actions',
          align: "center"
        }
      ];
    }
  },
  watch: {
    prepareForm: {
      handler() {

        clearTimeout(this.awaitingSearch);

        this.awaitingSearch = setTimeout(() => {
          this.loadItems();
        }, 1000);
      },
      deep: true,
    },
  },
  methods: {
    create() {
      let form = Object.assign({}, this.prepareForm)
      form.totalPositions = 0;
      form.totalItems = 0;
      form.totalNetBuyPrice = 0;
      form.expectedOn = Math.floor(new Date(form.expectedOn).getTime() / 1000);
      this.crateLoading = true;

      this.items = this.items.filter((item) => parseInt(item.orderQTY) > 0);

      this.items.forEach((item) => {

        if (item.orderQTY < 0) {
          item.orderQTY = 0.00;
        }
        if (item.orderQTY > 0) {
          ++form.totalPositions;
          form.totalItems += parseInt(item.orderQTY);
          form.totalNetBuyPrice += parseFloat(item.buy_price);
          item.orderPackageQTY = (Number(item.orderQTY / item.unitPerPackage) &&
              (item.orderQTY / item.unitPerPackage % 1
                  === 0)) ? item.orderQTY / item.unitPerPackage :
              ((item.orderQTY / item.unitPerPackage == 0) ? 0
                  : parseInt(item.orderQTY / item.unitPerPackage) + 1);
        }
      });

      form.items = this.items;


      this.axios.post(ENDPOINTS.ERP.ORDERWARE.CREATE, form).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.items = null;
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "success"
          });
          this.$emit('input', "tab2")
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.crateLoading = false;
      })
    },
    clear() {
      this.$swal({
        title: this.$t('erp.lang_nav_accessories_cleanUp') + '?',
        text: this.$t('erp.lang_nav_accessories_cleanUp') + '?',
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_no'),
        confirmButtonText: this.$t('generic.lang_yes'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.items = null;
          this.itemsHistory = {};
          this.prepareForm.storageID = 0
          this.prepareForm.supplierID = 0
          this.prepareForm.showAll = false
          this.prepareForm.itemID = null
          this.prepareForm.wareGroupID = null
          this.prepareForm.producerID = null
          this.prepareForm.orderName = null
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteItem(item) {
      let index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    getColor(currentQTY, minQTY, standQTY) {
      if (currentQTY <= minQTY)
        return "error";
      else if (currentQTY >= minQTY && currentQTY < standQTY)
        return "warning";
      else return "success";

    },
    loadItems() {

      if (!this.$refs.prepare_form.validate()) return;

      this.loading = true;

      // cache the order qty of all items
      if(this.items && this.items.length > 0){
        this.items.forEach((item) => {
          this.itemsHistory[item.identcode] = item.orderQTY;
        });
      }

      this.axios.post(ENDPOINTS.ERP.ORDERWARE.GET, this.prepareForm).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.items = res.data.data;

          this.items.forEach((item) => {
            if (item.orderQTY < 0) {
              item.orderQTY = 0.00;
            }

            if(this.itemsHistory.hasOwnProperty(item.identcode)){
              item.orderQTY = this.itemsHistory[item.identcode];
            }
          });

        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        }
      }).catch((err) => {

      }).finally(() => {
        this.loading = false;
      })
    },
    loadData() {
      let promises = [];
      promises.push(this.axios.post(ENDPOINTS.ERP.SUPPLIERS.GETALL));
      promises.push(this.axios.post(ENDPOINTS.ERP.STORAGES.GET));
      Promise.all(promises).then(this.axios.spread((...res) => {
        if (res[0].data.STATUS === "SUCCESS") {
          this.suppliers = res[0].data.suppliers;
        }

        this.storages = [];
        res[1].data.storageInformation.forEach((store) => {
          this.storages.push({
            storageID: store[0],
            storageName: store[1]
          })
        })


      })).catch((err) => {

      })
    },
    getProducers() {
      this.axios.post(ENDPOINTS.ERP.PRODUCERS.GETALL).then((res) => {
        if (res.data.success)
          this.producers = res.data.producers;
      }).catch((err) => {

      }).finally(() => {
        this.loading = false;
      })
    },
    async getItemGroups() {
      let itemGroups = await this.$store.dispatch("itemgroups/getItemgroups");
      this.itemGroups = itemGroups.map((itemgroup) => {
        return {
          id: itemgroup.id,
          name: itemgroup.name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  }
}
</script>

<style scoped>
/* #showAllCheckbox {
 border:1px solid #ccc;
 padding:10px;
 margin:0 0 10px;
 display:block; 
}

#showAllCheckbox:hover {
 background:#eee;
 cursor:pointer;
} */
</style>
