<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_nav_wareorder') "
                :icon="icon"
                :subheading="$t('erp.lang_nav_wareorder')"
        ></page-title>
        <div class="app-main__inner">
            <order-ware-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import OrderWareComponent from "../../../../components/erp/storage/orderware/OrderWareComponent";


    export default {
        components: {
            OrderWareComponent,
            PageTitle,
        },
        data: () => ({
            icon: "pe-7s-cart icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>